import React, { Component } from 'react';
import './css/Loader.css';

export default class Loader extends Component {
  render() {
    return (
      <div className="loader">
        <img alt="Loading..." src="/assets/loader.gif" />
      </div>
    )
  }
}