import React, { Component } from 'react';
import { Loader } from '../elements';
import API from '../services/api';
import './css/AuthorPage.css';

export default class AuthorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      author: null
    }
  }

  loadData = async () => {
    const { slug } = this.props.match.params;

    this.setState({
      isLoading: true
    });

    await API.get('', {
      params: {
        action: 'author',
        slug: slug
      }
    }).then(response => {
      if (response.data) {
        this.setState({
          author: response.data
        });
      }
    }).catch(error => {
      this.setState({
        loadingError: true
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { author } = this.state;

    if (!author) {
      return (
        <Loader />
      );
    }

    return (
      <div className="author">
        <div className="author__image" style={{ backgroundImage: author.imageUrl ? `url(${author.imageUrl})` : null }}></div>
        <h1 className="author__title">{author.name}</h1>
        <div className="author__content article" dangerouslySetInnerHTML={{ __html: author.bio }}></div>
      </div>
    )
  }
}