import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import GA from 'react-ga';
import './App.css';
import { config } from './config';

import {
  DefaultLayout,
  HomePageLayout
} from './layouts';

import {
  HomePage,
  AboutPage,
  CategoryPage,
  ContactPage,
  AuthorPage,
  PostPage,
} from './pages';

const createHistory = require('history').createBrowserHistory;

class App extends Component {
  constructor(props) {
    super(props);

    const history = createHistory();
    history.listen((location, action) => {
      this.trackPageview(location.pathname);
    });

    this.state = {
      history: history,
      user: null
    }

    GA.initialize(config.analyticsPropertyId);
    this.trackPageview(window.location.pathname);
  }

  trackPageview = (page) => {
    GA.set({ page: page });
    GA.pageview(page);
  }

  renderWithLayout = (Component, Layout, props) => {
    return <Layout><Component {...props} /></Layout>;
  }

  render() {
    return (
      <Router history={this.state.history}>
        <main id="app" className="app">
          <Switch>
            <Route exact path="/" render={(props) => this.renderWithLayout(HomePage, HomePageLayout, props)} />
            <Route path="/about" render={(props) => this.renderWithLayout(AboutPage, DefaultLayout, props)} />
            <Route path="/contact" render={(props) => this.renderWithLayout(ContactPage, DefaultLayout, props)} />
            <Route path="/author/:slug" render={(props) => this.renderWithLayout(AuthorPage, DefaultLayout, props)} />
            <Route path="/post/:id" render={(props) => this.renderWithLayout(PostPage, DefaultLayout, props)} />
            <Route path="/:slug" render={(props) => this.renderWithLayout(CategoryPage, DefaultLayout, props)} />
          </Switch>
        </main>
      </Router>
    );
  }
}

export default App;
