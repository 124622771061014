import React, { Component } from 'react';
import { Loader } from '../elements';
import API from '../services/api';
import './css/ContactPage.css';

export default class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      page: null
    }
  }

  loadData = async () => {
    this.setState({
      isLoading: true
    });

    await API.get('', {
      params: {
        action: 'page',
        slug: 'contact'
      }
    }).then(response => {
      if (response.data) {
        this.setState({
          page: response.data
        });
      }
    }).catch(error => {
      this.setState({
        loadingError: true
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { page } = this.state;

    if (!page) {
      return (
        <Loader />
      );
    }

    return (
      <div className="page">
        <h1 className="page__title">{page.title}</h1>
        <div className="page__content article" dangerouslySetInnerHTML={{ __html: page.content }}></div>
      </div>
    )
  }
}