import React, { Component } from 'react';
import { Loader } from '../elements';
import API from '../services/api';
import './css/AboutPage.css';

export default class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      bio: null
    }
  }

  loadData = async () => {
    this.setState({
      isLoading: true
    });

    await API.get('', {
      params: {
        action: 'author',
        slug: 'stefan-richardson'
      }
    }).then(response => {
      if (response.data) {
        this.setState({
          bio: response.data
        });
      }
    }).catch(error => {
      this.setState({
        loadingError: true
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { bio } = this.state;

    if (!bio) {
      return (
        <Loader />
      );
    }

    return (
      <>
        <div className="about">
          <div className="about__header">
            <iframe className="about__header__video" title="featured" src="https://player.vimeo.com/video/431160892?background=0&amp;autoplay=1&amp;controls=1&amp;loop=0&amp;byline=0&amp;title=0"
              frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
          </div>
        </div>
        <div className="about__bio">
          <div className="about__bio__image" style={{ backgroundImage: bio.profilePhotoUrl ? `url(${bio.profilePhotoUrl})` : null }}></div>
          <div className="about__bio__content">
            <h1 className="about__bio__title">{bio.name}</h1>
            <h2 className="about__bio__subtitle">{bio.subheader}</h2>
            <div className="about__bio__summary">
              {bio.summary}
            </div>
          </div>
        </div>
        <div className="about__bio__note">
          <div className="article" dangerouslySetInnerHTML={{ __html: bio.bio }}></div>
        </div>
      </>
    )
  }
}