import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Loader, PostPreview } from '../elements';
import API from '../services/api';
import './css/CategoryPage.css';

export default class CategoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      category: null
    }
  }

  loadData = async () => {
    const { slug } = this.props.match.params;

    this.setState({
      isLoading: true,
      category: null,
    });

    await API.get('', {
      params: {
        action: 'category',
        slug: slug
      }
    }).then(response => {
      if (response.data) {
        this.setState({
          category: response.data
        });
      }
    }).catch(error => {
      this.setState({
        loadingError: true
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { category } = this.state;

    if (!category) {
      return (
        <Loader />
      );
    }

    return (
      <div className="category">
        {category.featured &&
          <div className="category__featured">
            <iframe className="category__featured__video" title="featured" src={`https://player.vimeo.com/video/${category.featured.videoId}?background=0&amp;autoplay=0&amp;controls=1&amp;loop=1&amp;byline=0&amp;title=0`}
              frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
          </div>
        }
        <h1 className="category__title">{category.title}</h1>
        {category.author &&
          <div className="category__author">
            <div className="category__author__image" style={{ backgroundImage: category.author.imageUrl ? `url(${category.author.imageUrl})` : null }}></div>
            <div className="category__author__summary article">
              {category.author.summary}
              {` `}
              {category.author.hasBio === 1 &&
                <NavLink to={`/author/${category.author.slug}`}>Read More</NavLink>
              }
            </div>
          </div>
        }
        {category.posts &&
          <div className="category__posts">
            {category.posts.map((item, key) => (
              <PostPreview key={key} post={item} />
            ))}
          </div>
        }
      </div>
    )
  }
}