import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './css/Header.css';

export default class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			mobileMenuOpen: false,
		}
	}

	onMobileMenuClick = () => {
		this.setState({
			mobileMenuOpen: !this.state.mobileMenuOpen
		})
	}

	hideMobileMenu = () => {
		this.setState({
			mobileMenuOpen: false
		})
	}

	render() {
		let headerClassNames = ["header"]

		if (this.state.mobileMenuOpen) {
			headerClassNames.push("header--open")
		}

		return (
			<header className={headerClassNames.join(" ")}>
				<div className="wrap">
					<div className="desktop-menu">
						<div className="header__logo">
							<NavLink onClick={this.hideMobileMenu} to="/" className="header__link">
								<img alt="Blue Key Club" src="/assets/doa.svg" />
							</NavLink>
						</div>
						<div className="header__menu">
							<NavLink onClick={this.hideMobileMenu} to="/" className="header__link">
								Home
							</NavLink>
							<NavLink onClick={this.hideMobileMenu} to="/about" className="header__link" activeClassName="header__link--active">
								About
							</NavLink>
							<NavLink onClick={this.hideMobileMenu} to="/fitness" className="header__link" activeClassName="header__link--active">
								Fitness
							</NavLink>
							<NavLink onClick={this.hideMobileMenu} to="/food" className="header__link" activeClassName="header__link--active">
								Food
							</NavLink>
							<NavLink onClick={this.hideMobileMenu} to="/fashion" className="header__link" activeClassName="header__link--active">
								Fashion
							</NavLink>
							<NavLink onClick={this.hideMobileMenu} to="/function" className="header__link" activeClassName="header__link--active">
								Function
							</NavLink>
							<NavLink onClick={this.hideMobileMenu} to="/fun" className="header__link" activeClassName="header__link--active">
								Fun
							</NavLink>
							<NavLink onClick={this.hideMobileMenu} to="/contact" className="header__link" activeClassName="header__link--active">
								Contact
							</NavLink>
						</div>
					</div>
					<div className="mobile-menu">
						<img onClick={this.onMobileMenuClick} className="mobile-menu__trigger" alt="" src="/assets/icons/menu.svg" />
					</div>
				</div>
			</header>
		)
	}
}