import React, { Component } from 'react';
import { Header } from '../layout';

export default class HomePageLayout extends Component {
  render() {
    return (
      <div className="home">
        <Header />
        {this.props.children}
      </div>
    );
  }
}