import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './css/Loader.css';

export default class PostPreview extends Component {
  render() {
    const { post } = this.props;

    return (
      <NavLink className="category__post__link" to={`/post/${post.id}`}>
        <span className="category__post">
          <span className="category__post__image" style={{ backgroundImage: post.imageUrl ? `url(${post.imageUrl})` : null }}></span>
          <span className="category__post__content">
            <h2>{post.title}</h2>
            <p>{post.summary}</p>
          </span>
        </span>
      </NavLink>
    )
  }
}