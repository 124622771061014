import React, { Component } from 'react';

export default class HomePage extends Component {
  render() {
    return (
      <div>
        <div className="home__background"></div>
        <div className="video-wrapper">
          <iframe title="home" src="https://player.vimeo.com/video/431165823?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0"
            frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
        </div>
        <div className="main__content">
          <h1>Dad On Arrival</h1>
          <p>Dad hacks, legit laughs and how to survive while raising a small human. All without losing yourself along the way.</p>
        </div>
        <div className="border">
          <div className="border__inside"></div>
        </div>
      </div>
    )
  }
}